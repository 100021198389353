import { useSelector } from 'react-redux';
import { graphQlFetch } from '../../Utilities/Utilities';

export const STORE_LOADING = 'STORE_LOADING';
export const storeLoadingAction = () => ({
  type: STORE_LOADING,
});
export const STORE_LOAD_ERROR = 'STORE_LOAD_ERROR';
export const storeLoadingErrorAction = (trigger, error) => ({
  type: STORE_LOAD_ERROR,
  data: `${trigger}: ${error}`,
});
// chip partial crop_id urlString

export const GET_CHIP_PRODUCTS = 'GET_CHIP_PRODUCTS';
export const getChipProducts = (access, chip, chipSelected, chipProducts) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }
    // need to send priceMulti to php so it sends back correct val
    // instead of setting it back to initial price on any dispatch
    const sendArray = [];
    if (chipProducts) {
      for (let i = 0; i < chipProducts.length; i += 1) {
        sendArray[i] = `pM${chipProducts[i].id}:${chipProducts[i].priceMulti}`;
      }
    }

    const body = `{
      "chip": "${chip}",
      "chipProducts": "${sendArray}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      chipSelected(result.productArray);
      return dispatch({
        type: 'GET_CHIP_PRODUCTS',
        data: result.productArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_SEARCH_PRODUCTS = 'GET_SEARCH_PRODUCTS';
export const getSearchProducts = (access, partial, chipSelected, chipProducts) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }
    // need to send priceMulti to php so it sends back correct value
    // instead of setting it back to initial price on any dispatch
    const sendArray = [];
    if (chipProducts) {
      for (let i = 0; i < chipProducts.length; i += 1) {
        sendArray[i] = `pM${chipProducts[i].id}:${chipProducts[i].priceMulti}`;
      }
    }

    const body = `{
      "partial": "${partial}",
      "chipProducts": "${sendArray}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      chipSelected(result.productArray);
      return dispatch({
        type: 'GET_SEARCH_PRODUCTS',
        data: result.productArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CROP_PRODUCTS = 'GET_CROP_PRODUCTS';
export const getCropProducts = (access, crop, chipSelected, chipProducts) => (
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }
    // just need to send priceMulti to php so it sends back correct value
    //  instead of setting it back to initial price on any dispatch
    const sendArray = [];
    if (chipProducts) {
      for (let i = 0; i < chipProducts.length; i += 1) {
        sendArray[i] = `pM${chipProducts[i].id}:${chipProducts[i].priceMulti}`;
      }
    }

    const body = `{
      "crop_id": "${crop}",
      "chipProducts": "${sendArray}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      chipSelected(result.productArray);
      return dispatch({
        type: 'GET_CROP_PRODUCTS',
        data: result.productArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const getAllProducts = (access, chipSelected, chipProducts) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }

    // just need to send priceMulti to php so it sends back correct value
    // instead of setting it back to initial price on any dispatch
    const sendArray = [];
    if (chipProducts) {
      for (let i = 0; i < chipProducts.length; i += 1) {
        sendArray[i] = `pM${chipProducts[i].id}:${chipProducts[i].priceMulti}`;
      }
    }

    const body = `{
      "partial": "",
      "chipProducts": "${sendArray}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      chipSelected(result.productArray);
      return dispatch({
        type: 'GET_ALL_PRODUCTS',
        data: result.productArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const GET_CHECKOUT_PRODUCTS = 'GET_CHECKOUT_PRODUCTS';
export const getCheckoutProducts = (access, urlString, cartProducts, checkoutProductsAdd) => (
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }
    // may just need the id and quantity of the products already in cart
    const sendArray = [];
    if (cartProducts) {
      for (let i = 0; i < cartProducts.length; i += 1) {
        sendArray[i] = `${cartProducts[i].id}:${cartProducts[i].quantity}`;
      }
    }

    const body = `{
      "urlString": "${urlString}",
      "cartProducts": "${sendArray}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_product_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      checkoutProductsAdd(result.productArray);
      return dispatch({
        type: 'GET_CHECKOUT_PRODUCTS',
        data: result.productArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const GET_CHECKOUT_CUSTOMER = 'GET_CHECKOUT_CUSTOMER';
export const getCheckoutCustomer = (access, checkoutCustomerLoad) => (
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }

    const body = `{
      "test": "123"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_checkout_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      // if logged in, skip to second active step and load in info from returned object
      if (result.currentID) {
        checkoutCustomerLoad(result.customerArray, result.currentID);
      }
      return dispatch({
        type: 'GET_CHECKOUT_CUSTOMER',
        data: result.customerArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const CHECKOUT_USER_VALIDATE = 'CHECKOUT_USER_VALIDATE';
export const checkoutUserValidate = (access, user, pass, checkoutCustomerLoad, renderError) => (
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }

    const body = `{
      "password": "${pass}",
      "user": "${user}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_checkout_resource`, requestOptions);
    // between these 2 actions the form is closing if user is not logged in while breakpoints active
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      // if logged in, skip to second active step and load in info from returned object
      if (result.currentID) {
        checkoutCustomerLoad(result.customerArray, result.currentID);
      }
      return dispatch({
        type: 'CHECKOUT_USER_VALIDATE',
        data: result.customerArray,
      });
    }
    if (await result.status === 420) {
      renderError();
      return dispatch({
        type: 'CHECKOUT_USER_VALIDATE',
        data: result.customerArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const APPLY_DISCOUNT_VALIDATE = 'APPLY_DISCOUNT_VALIDATE';
export const applyDiscountValidate = (access, discountCode, renderError, discountValidated) => (
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
    }

    const body = `{
      "discountCode": "${discountCode}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_checkout_resource`, requestOptions);
    const result = await JSON.parse(await response.text());

    if (await result.status === 200) {
      // maybe we should disable the button/allow removal & entry of different discount if limited
      // to 1 per order if multiple allowed, may add new row down & replace apply button on applied
      // code to remove, could put validation for credit card info on the next button instead of
      // Place Order, or change after validation replace (3)->confirm with payment maybe???
      discountValidated(result.discountArray.code);
      return dispatch({
        type: 'APPLY_DISCOUNT_VALIDATE',
        data: result.discountArray,
      });
    }
    if (await result.status === 420) {
      renderError(result.discountArray.message);
      return dispatch({
        type: 'APPLY_DISCOUNT_VALIDATE',
        data: result.discountArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const PURCHASE_VALIDATE = 'PURCHASE_VALIDATE';
export const purchaseValidate = (access, discountCode, email, shipState,
  shipZip, cartProducts, checkoutCustomerLoad, renderError) => (
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    const user = useSelector((state) => state.store.customerArray);
    let accType = '';

    myHeaders.append('Content-Type', 'application/json');
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
      accType = user.uid;
      await dispatch(getCheckoutCustomer(access, checkoutCustomerLoad));
    } else {
      accType = 'anonymous';
    }

    const cartArray = [];

    // in this call, cart Array consists of objects with two values (id and quantity)
    if (cartProducts) {
      for (let i = 0; i < cartProducts.length; i += 1) {
        const cartItem = `{
          'product_id:' '${cartProducts[i].product_id}',
          'quantity:' '${cartProducts[i].quantity}'
        }`;
        cartArray[i].push(cartItem);
      }
    }

    const body = `{
      "discountCode": "${discountCode}",
      "accountType": "${accType}",
      "purchaser_email": "${email}",
      "shipping_state": "${shipState}",
      "shipping_zip": "${shipZip}",
      "cart_content": "${cartArray}",
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_checkout_resource`, requestOptions);
    const result = await JSON.parse(await response);

    if (await result.status === 200) {
      return dispatch({
        type: 'PURCHASE_VALIDATE',
        data: result.purchaseArray,
      });
    }
    if (await result.status === 420) {
      renderError();
      return dispatch({
        type: 'PURCHASE_VALIDATE',
        data: result.purchaseArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const PURCHASE_FINALIZE = 'PURCHASE_FINALIZE';
export const purchaseFinalize = (
  access,
  paymentToken,
  discountCode,
  customerData,
  cartProducts,
  checkoutCustomerLoad,
  renderError) => (
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const cartArray = [];
    let accType = '';
    const user = useSelector((state) => state.store.customerArray);
    if (access) {
      myHeaders.append('Authorization', `Bearer ${access}`);
      accType = user.uid;
      await dispatch(getCheckoutCustomer(access, checkoutCustomerLoad));
    } else {
      accType = 'anonymous';
    }

    if (cartProducts) {
      for (let i = 0; i < cartProducts.length; i += 1) {
        const cartItem = `{
          'product_id:' '${cartProducts[i].product_id}',
          'quantity:' '${cartProducts[i].quantity}'
        }`;
        cartArray[i].push(cartItem);
      }
    }

    const body = `{
      "payment_token": "${paymentToken}",
      "discountCode": "${discountCode}",
      "accountType": "${accType}",
      "purchaser_email": "${customerData.email}",
      "first_name": "${customerData.firstName}",
      "last_name": "${customerData.lastName}",
      "form_email": "${customerData.email}",
      "shipping_address_one": "${customerData.address1}",
      "shipping_address_two": "${customerData.address2}",
      "shipping_city": "${customerData.city}",
      "shipping_state": "${customerData.state}",
      "shipping_zip": "${customerData.postal}",
      "billing_address_one": "${customerData.billingAddress1}",
      "billing_address_two": "${customerData.billingAddress2}",
      "billing_city": "${customerData.billingCity}",
      "billing_state": "${customerData.billingState}",
      "billing_zip": "${customerData.billingPostal}",
      "cart_content": "${cartArray}",
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_checkout_resource`, requestOptions);
    const result = await JSON.parse(await response);

    if (await result.status === 200) {
      return dispatch({
        type: 'PURCHASE_FINALIZE',
        data: result.finalPurchaseArray,
      });
    }
    if (await result.status === 420) {
      renderError();
      return dispatch({
        type: 'PURCHASE_FINALIZE',
        data: result.finalPurchaseArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
    return null;
  }
);

export const GET_DISCOUNT_TYPES = 'GET_DISCOUNT_TYPES';
export const getDiscountTypes = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const query = `{
      discountTypes {
        items {
          id
          uuid
          name
          type
          code
          value
          quantity
          start_date
          end_date
        }
      }
    }`;
    const func = graphQlFetch(access, query);
    const result = await func();
    if (await result.data.discountTypes.items) {
      const discountArray = await result.data.discountTypes.items;

      // date formatting and sorting: true parameter will sort newest to oldest
      // and format to mm/dd/yyyy - hh:mm:ss
      // if (isDateReturnFormatted) {
      discountArray.sort((a, b) => b.created - a.created);
      discountArray.forEach((e) => {
        const entry = e;
        const startDate = new Date(entry.start_date * 1000);
        const formattedStartDate = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
        entry.start_date = `${formattedStartDate}`;
        if (entry.end_date) {
          const endDate = new Date(entry.end_date * 1000);
          const formattedEndDate = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
          entry.end_date = `${formattedEndDate}`;
        }
      });
      // }

      return dispatch({
        type: 'GET_DISCOUNT_TYPES',
        data: discountArray,
        columns: [
          { title: 'ID', field: 'id' },
          { title: 'UUID', field: 'uuid' },
          { title: 'Name', field: 'name' },
          { title: 'Type', field: 'type' },
          { title: 'Code', field: 'code' },
          { title: 'Value', field: 'value' },
          { title: 'Quantity', field: 'quantity' },
          { title: 'Start Date', field: 'start_date' },
          { title: 'End Date', field: 'end_date' },
        ],
      });
    }
    if (!result.data.discountTypes.items) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const TOGGLE_DISCOUNT_DIALOG = 'TOGGLE_DISCOUNT_DIALOG';
export const toggleDiscountDialog = (data) => (
  function (dispatch) {
    let rowData = data;
    if (!rowData) {
      rowData = {};
    }
    return dispatch({
      type: TOGGLE_DISCOUNT_DIALOG,
      data: rowData,
    });
  }
);

export const GET_DISCOUNT_TYPE = 'GET_DISCOUNT_TYPE';
export const getDiscountType = (access) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_store/adapify_discountType_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const discountArray = await JSON.parse(result.discount);

    if (await result.status === 200) {
      return dispatch({
        type: 'GET_DICSOUNT_TYPE',
        data: discountArray,
      });
    }
    if (await result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const SAVE_DISCOUNT = 'SAVE_DISCOUNT';
export const saveDiscount = (access, code, discountId, start, end, type) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${access}`);

    const body = `{
      "id": "${discountId}",
      "name": "${code.name}",
      "type": "${type}",
      "code": "${code.code}",
      "value": "${code.value}",
      "quantity": "${code.quantity}",
      "startDate": "${start}",
      "endDate": "${end}"
    }`;

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };
    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/adapify_discountType_resource`, requestOptions);
    const result = await JSON.parse(await response.text());
    const discountArray = await result.discount;

    if (result.status === 200) {
      await dispatch(getDiscountTypes(access));
      return dispatch({
        type: 'SAVE_DISCOUNT',
        data: discountArray,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
  }
);

export const CONFIRM_PURCHASE_OWNERSHIP_STATUS = 'CONFIRM_PURCHASE_OWNERSHIP_STATUS';
export const confirmPurchaseOwnership = (
  amount, description, cart, email, source, discountCode,
  acceptAds, firstName, lastName, address1, address2, city, state, postalCode, paymentId,
  orderIsGift, giftOrderMessage, recipientFullName, accountAtTimeOfPurchase,
) => (
  // eslint-disable-next-line consistent-return
  async function (dispatch) {
    dispatch({ type: STORE_LOADING });
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    // let body = `{
    //   "amount": "${amount}",
    //   "description": "${description}",
    //   "cart": "${cart}",
    //   "email": "${email}",
    //   "source": "${source}",
    //   "account_at_time_of_purchase": "${accountAtTimeOfPurchase}",
    //   "discount_code": "${discountCode}",
    //   "accept_ads": "${acceptAds}",
    //   "name": "arianna jones",
    //   "address1": "${address1}",
    //   "address2": "${address2}",
    //   "city": "${city}",
    //   "state": "${state}",
    //   "postal_code": "${postalCode}",
    //   "payment_id": "${paymentId}",
    //   "order_is_gift": "${orderIsGift}",
    //   "gift_order_message": "${giftOrderMessage}",
    //   "recipient_full_name": "${recipientFullName}"
    // }`;

    const body = JSON.stringify({
      type: 'purchase_confirmed',
      amount,
      description,
      cart,
      email,
      source,
      account_at_time_of_purchase: accountAtTimeOfPurchase,
      discount_code: discountCode,
      accept_ads: acceptAds,
      name: `${firstName} ${lastName}`,
      address1,
      address2,
      city,
      state,
      postal_code: postalCode,
      payment_id: paymentId,
      order_is_gift: orderIsGift,
      gift_order_message: giftOrderMessage,
      recipient_full_name: recipientFullName,
    });

    // body = JSON.stringify(JSON.parse(body));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    };

    const response = await fetch(`${process.env.REACT_APP_ADAPIFY_SITE_URL}/stripe_fetch_intent`, requestOptions);
    const result = await response.json();

    if (result.status === 200) {
      return dispatch({
        type: 'CONFIRM_PURCHASE_OWNERSHIP_STATUS',
        data: result,
      });
    }
    if (result.ok === false) {
      const err = result;
      return dispatch({
        type: STORE_LOAD_ERROR,
        data: err,
      });
    }
  }
);
