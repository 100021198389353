// React Redux Core
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
// MUI and other Libraries
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Divider,
  IconButton,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// Adapify
import { useSignOut } from 'react-auth-kit';
// import theme from '../theme';

const UserMenu = () => {
  const signOut = useSignOut();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        color="inherit"
        ref={anchorRef}
        aria-label="account of current user"
        aria-haspopup="true"
        aria-controls={open ? 'menu-list-grow' : undefined}
        onClick={handleToggle}
      >
        <AccountCircle sx={{ color: 'black' }} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom-end', left: '0px' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/settings"
                    sx={{
                      paddingTop: '6px',
                      paddingBottom: '6px',
                    }}
                  >
                    <SettingsIcon
                      sx={{
                        marginRight: '8px',
                        color: 'rgba(0, 0, 0, 0.54)',
                      }}
                    />
                    Settings
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/orders"
                    sx={{
                      paddingTop: '6px',
                      paddingBottom: '6px',
                    }}
                  >
                    <ViewListIcon
                      sx={{
                        marginRight: '8px',
                        color: 'rgba(0, 0, 0, 0.54)',
                      }}
                    />
                    Orders
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      signOut();
                      localStorage.setItem('_auth', null);
                      localStorage.setItem('_auth_refresh', null);
                      window.location.reload(false);
                    }}
                    sx={{
                      paddingTop: '6px',
                      paddingBottom: '6px',
                    }}
                  >
                    <ExitToAppIcon
                      sx={{
                        marginRight: '8px',
                        color: 'rgba(0, 0, 0, 0.54)',
                      }}
                    />
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default UserMenu;
