// import React, { useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOwnership } from './redux/actions';

const PurchaseOwnershipPoller = (props) => {
  const {
    parameters, callbackSet, customer, source, transactionData,
  } = props;
  const purchaseUserLoginState = useSelector((state) => state.utilities.responseOwnershipStatus);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (purchaseUserLoginState !== null && (purchaseUserLoginState.last_login === 'never' || purchaseUserLoginState.last_login === 'exists')) {
      callbackSet(purchaseUserLoginState);
    } else if (purchaseUserLoginState !== null && purchaseUserLoginState.last_login === 'unknown' && retryAttempt < 5) {
      const purchaseId = parameters;
      setRetryAttempt(retryAttempt + 1);
      if (source) {
        setTimeout(
          (purchaseId) => {
            dispatch(getPurchaseOwnership(purchaseId, retryAttempt + 1, customer.email,
              source, transactionData));
          },
          3000, purchaseId);
      } else {
        setTimeout(
          (purchaseId) => {
            dispatch(getPurchaseOwnership(purchaseId, retryAttempt + 1, customer.email));
          },
          3000, purchaseId);
      }
    } else if (purchaseUserLoginState !== null && purchaseUserLoginState.last_login === 'unknown' && retryAttempt >= 5) {
      callbackSet('timeout');
    }
  }, [purchaseUserLoginState]);

  useEffect(() => {
    if (parameters !== null && (!source || source !== 'express_login_check')) {
      dispatch(getPurchaseOwnership(parameters, retryAttempt, customer.email, null, null));
    } else if (parameters !== null && source && transactionData) {
      console.log(`This type: ${source}`);
      dispatch(getPurchaseOwnership(parameters, retryAttempt, customer.email,
        source, transactionData));
    }
  }, [parameters]);

  return (<></>);
};

export default PurchaseOwnershipPoller;
