// React Redux Core
import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { AuthProvider } from 'react-auth-kit';
// MUI and other Libraries
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
// Adapify
import rootReducer from './rootReducer';
import theme from './theme';
import App from './App';

// const rootElement = document.getElementById('root');
const middleware = [thunk, logger];//  ||
const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware)),
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider
    authType="localstorage"
    authName="_auth"
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === 'https:'}
    refreshToken
  >
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  </AuthProvider>,
);

// ReactDOM.render(
//   <AuthProvider
//     authType="localstorage"
//     authName="_auth"
//     cookieDomain={window.location.hostname}
//     cookieSecure={window.location.protocol === 'https:'}
//     refreshToken
//   >
//     <ThemeProvider theme={theme}>
//       <Provider store={store}>
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//           <App />
//         </LocalizationProvider>
//       </Provider>
//     </ThemeProvider>
//   </AuthProvider>,
//   rootElement,
// );
