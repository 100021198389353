import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
  ClickAwayListener,
  Dialog,
  Button,
  ButtonGroup,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Icon,
  Tooltip,
} from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import theme from '../theme';

// const useStyles = makeStyles(() => ({
//   quantityButton: {
//     border: '1px solid rgba(0, 0, 0, 0.87)',
//     borderTop: 'none',
//     borderRight: 'none',
//     cursor: 'pointer',
//     textAlign: 'center',
//     fontSize: 14,
//     lineHeight: 1,
//     userSelect: 'none',
//     transform: 'translateX(-100%)',
//     borderRadius: 0,
//     minWidth: 20,
//     width: 20,
//     minHeight: 10,
//     Height: 10,
//     padding: 0,
//   },
//   quantityUp: {
//     position: 'absolute',
//     height: 'calc(50% - 8px)',
//     top: 16,
//   },
//   quantityDown: {
//     borderTop: '1px solid rgba(0, 0, 0, 0.87)',
//     borderBottom: 'none',
//     position: 'absolute',
//     height: 'calc(50% - 8px)',
//     bottom: 1,
//   },
// }));

const StoreCart = (props) => {
  const { checkout, updateCheckout } = props;
  const newQuantity = useSelector((state) => state.dashboard.newQuantity);
  const [cartObject, setCartObject] = useState([]);
  const [priceTotal, setPriceTotal] = useState('No Items in Cart');
  const [itemTotal, setItemTotal] = useState(0);
  const [cartOpen, setCartOpen] = useState(false);
  const [compare, setCompare] = useState(false);
  const [confirmEmpty, setConfirmEmpty] = useState(false);
  const anchorRef = useRef(null);
  const max = 999;
  const min = 1;

  const storeCart = {
    position: 'relative',
    marginRight: '7px',
    marginTop: '1px',
    [theme.breakpoints.down(409)]: {
      marginRight: 0,
    },
  };
  const quantity = {
    margin: 'auto auto 10px 10px',
    marginLeft: '15px',
    marginRight: '15px',
    position: 'relative',
    borderRadius: 0,
    marginTop: '-10px',
    '& input[type=number]': {
      width: 33,
      height: 42,
      padding: '0 26px 0 8px',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid rgba(0, 0, 0, 0.87)',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
    '& label': {
      fontSize: 14,
      paddingTop: '7px',
      paddingLeft: '8px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'calc(100% - 28px)',
      color: 'white',
    },
    '& label.MuiInputLabelShrink': {
      paddingTop: 0,
      paddingLeft: '2px',
      width: '100%',
      overflow: 'visible',
      color: 'white',
    },
  };
  const quantityField = {
    borderRadius: '0px',
    height: '52px',
    width: '73px',
    '& .MuiInputBase-root': {
      height: '52px',
    },
  };
  const checkoutLink = {
    color: 'white',
    textDecoration: 'none',
  };
  const removeButton = {
    padding: 0,
    marginRight: '5px',
    minWidth: 'auto',
  };
  const productName = {
    minWidth: '55%',
  };
  const priceTotalStyle = {
    float: 'right',
    marginRight: '20px',
    fontSize: 16,
    fontWeight: 600,
  };
  const buttonWrapper = {
    float: 'right',
  };
  const emptyButton = {
    marginRight: '10px',
  };
  const checkoutButton = {
    marginRight: '10px',
  };
  const popper = {
    zIndex: 10001,
    width: 500,
    transform: 'translate3d(224%, 50px, 0px)',
    [theme.breakpoints.down(1260)]: {
      transform: 'translate3d(174%, 50px, 0px)',
    },
    [theme.breakpoints.down(1035)]: {
      transform: 'translate3d(134%, 50px, 0px)',
    },
    [theme.breakpoints.down(850)]: {
      transform: 'translate3d(114%, 50px, 0px)',
    },
    [theme.breakpoints.down(730)]: {
      transform: 'translate3d(94%, 50px, 0px)',
    },
    [theme.breakpoints.down(625)]: {
      transform: 'translate3d(10%, 50px, 0px)',
    },
    [theme.breakpoints.down(606)]: {
      width: 264,
      transform: 'translate3d(7%, 50px, 0px)',
    },
  };
  const productImage = {
    height: 'auto',
    width: 50,
    objectFit: 'cover',
    marginRight: '10px',
  };
  const noItems = {
    margin: '15px',
  };
  const confirmEmptyWrapper = {
    padding: '8px',
    zIndex: 10005,
    width: 420,
    height: 'auto',
  };
  const confirmEmptyTitle = {
    fontSize: 30,
    marginLeft: '5px',
    fontWeight: 500,
  };
  const confirmEmptyContent = {
    fontSize: 16,
    marginLeft: '10px',
    marginTop: '20px',
    marginBottom: '20px',
  };
  const emptyButtonWrapper = {
    marginTop: '15px',
  };
  const emptyConfirm = {
    float: 'right',
    marginLeft: '10px',
  };
  const emptyCancel = {
    float: 'right',
  };
  const listItems = {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(606)]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  };
  const popperActions = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: '10%',
    [theme.breakpoints.down(606)]: {
      marginLeft: 0,
      alignSelf: 'flex-end',
    },
  };
  const dialogBox = {
    height: '124%',
    [theme.breakpoints.down(606)]: {
      height: '143%',
    },
  };
  const quantityUp = {
    position: 'absolute',
    height: 'calc(50% - 8px)',
    top: 16,
    // height: '38%',
    padding: '0px',
    borderRadius: '0px',
    border: 'none',
    // border: '1px solid rgba(0, 0, 0, 0.22)',
    color: 'rgba(0, 0, 0, 0.72)',
    fontWeight: '800',
  };
  const quantityDown = {
    borderTop: '1px solid rgba(0, 0, 0, 0.87)',
    borderBottom: 'none',
    position: 'absolute',
    height: 'calc(50% - 8px)',
    bottom: 1,
    // height: '38%',
    padding: '0px',
    border: 'none',
    borderRadius: '0px',
    color: 'rgba(0, 0, 0, 0.72)',
    fontWeight: '800',
  };
  const productPrice = {
    flex: '1 1 auto',
    minWidth: 40,
    marginTop: '4px',
    marginBottom: '4px',
    textAlign: 'center',
  };

  const clickHandler = (wasOpen) => {
    if (wasOpen === false) { // only open on initial click
      setCartOpen(true);
    }
    const currentCart = window.localStorage.getItem('cart');
    if (!currentCart || currentCart === '[]') {
      setCartObject([]);
    } else {
      setCartObject(JSON.parse(currentCart));
      const theCart = window.localStorage.getItem('cart');
      if (theCart) {
        let totalPrice = 0;
        let totalItems = 0;
        const theObject = JSON.parse(theCart);
        for (let i = 0; i < theObject.length; i += 1) {
          totalPrice += theObject[i].price * theObject[i].quantity;
          totalItems += theObject[i].quantity;
          setPriceTotal(`Total Price: $${parseFloat(totalPrice).toFixed(2)}`);
          setItemTotal(totalItems);
          if (totalItems === 0) {
            setPriceTotal('No Items in Cart');
          }
        }
      }
    }
  };

  const handleToggle = () => {
    setCartOpen((prevOpen) => !prevOpen);
    clickHandler(false);
  };

  const openConfirmEmpty = () => {
    setConfirmEmpty(true);
  };

  const emptyCart = () => {
    window.localStorage.removeItem('cart');
    setPriceTotal('No Items in Cart');
    setItemTotal(0);
    clickHandler(true);
    if (checkout === 'checkout') { // ******************************************* */
      updateCheckout([]);
    }
    setConfirmEmpty(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(cartOpen);
  useEffect(() => {
    if (prevOpen.current === true && cartOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = cartOpen;
  }, [cartOpen]);

  const clickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setCartOpen(false);
    setConfirmEmpty(false);
  };

  const removeProduct = (id) => {
    const removeCart = window.localStorage.getItem('cart');
    if (removeCart) {
      const removeObject = JSON.parse(removeCart);
      for (let i = 0; i < removeObject.length; i += 1) {
        if (removeObject[i].id === id) {
          removeObject.splice(i, 1);
          window.localStorage.setItem('cart', JSON.stringify(removeObject));
        }
      }
    }
    const afterCart = window.localStorage.getItem('cart');
    const afterObject = JSON.parse(afterCart);
    if (afterObject.length === 0) {
      setPriceTotal('No Items in Cart');
      setItemTotal(0);
    }
    let totalPrice = 0;
    let totalItems = 0;
    for (let i = 0; i < afterObject.length; i += 1) {
      totalPrice += afterObject[i].price * afterObject[i].quantity;
      totalItems += afterObject[i].quantity;
      setPriceTotal(`Total Price: $${parseFloat(totalPrice).toFixed(2)}`);
      setItemTotal(totalItems);
    }
    clickHandler(true);
    if (checkout === 'checkout') { // ******************************************* */
      updateCheckout(afterObject, 'true');
    }
  };

  const quantitySet = (productQuantity, productId) => {
    const quantityCart = window.localStorage.getItem('cart');
    if (quantityCart) {
      let totalPrice = 0;
      let totalItems = 0;
      const quantityObject = JSON.parse(quantityCart);
      for (let i = 0; i < quantityObject.length; i += 1) {
        // update the price and quantity on specific product
        if (quantityObject[i].id === productId) {
          quantityObject[i].quantity = productQuantity;
          setCartObject(quantityObject);
          window.localStorage.setItem('cart', JSON.stringify(quantityObject));
        }
        totalPrice += quantityObject[i].price * quantityObject[i].quantity;
        totalItems += quantityObject[i].quantity;
        setPriceTotal(`Total Price: $${parseFloat(totalPrice).toFixed(2)}`);
        setItemTotal(totalItems);
      }
      // cart updated, pass back to checkout page
      if (checkout === 'checkout') { // ******************************************* */
        updateCheckout(quantityObject, 'true');
      }
    }
  };

  const btnUpClick = (newValue, productId) => {
    if (newValue >= max) {
      quantitySet(max, productId);
    } else {
      const newVal = (newValue + 1);
      quantitySet(newVal, productId);
    }
  };
  const btnDownClick = (newValue, productId) => {
    if (newValue <= min) {
      quantitySet(min, productId);
    } else {
      quantitySet(newValue - 1, productId);
    }
  };
  const setMinMax = (newValue, productId) => {
    if (newValue < min) {
      quantitySet(min, productId);
    } else if (newValue > max) {
      quantitySet(max, productId);
    }
  };

  // NOTE: when products are added through the url they do not yet exist in
  // the cart when this function runs so will not update quantity
  const checkItems = () => {
    setCompare(newQuantity);
    const quantityCart = window.localStorage.getItem('cart');
    if (quantityCart) {
      let totalItems = 0;
      const quantityObject = JSON.parse(quantityCart);
      for (let i = 0; i < quantityObject.length; i += 1) {
        // update the price and quantity on specific product
        totalItems += quantityObject[i].quantity;
        setItemTotal(totalItems);
      }
    }
    if (newQuantity === 'checkout-0') {
      setItemTotal(0);
      setPriceTotal('No Items in Cart');
    }
  };

  useEffect(() => {
    const currentCart = window.localStorage.getItem('cart');
    if (!currentCart || currentCart === '[]') {
      setCartObject([]);
    } else {
      setCartObject(JSON.parse(currentCart));
      const theCart = window.localStorage.getItem('cart');
      if (theCart) {
        let totalPrice = 0;
        let totalItems = 0;
        const theObject = JSON.parse(theCart);
        for (let i = 0; i < theObject.length; i += 1) {
          totalPrice += theObject[i].price * theObject[i].quantity;
          totalItems += theObject[i].quantity;
          setPriceTotal(`Total Price: $${parseFloat(totalPrice).toFixed(2)}`);
          setItemTotal(totalItems);
          if (totalItems === 0) {
            setPriceTotal('No Items in Cart');
          }
        }
      }
    }
  }, [window.localStorage]);

  return (
    <div style={storeCart}>
      <Button sx={{ color: 'black' }} onClick={handleToggle} ref={anchorRef} aria-controls={cartOpen ? 'menu-list-grow' : undefined} aria-haspopup="true">
        <ShoppingCartOutlinedIcon />
        {compare !== newQuantity ? (checkItems()) : (itemTotal) }
      </Button>
      <Popper open={cartOpen} placement="bottom-end" anchorEl={anchorRef.current} role={undefined} transition sx={popper}>
        <Paper>
          <ClickAwayListener onClickAway={clickAway}>
            <List>
              {cartObject.map((product, i) => {
                const u = i;
                return (
                  <div key={u}>
                    <ListItem key={`wrapper-${product.id}`} sx={listItems}>
                      <div
                        style={{
                          display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '6px', width: '80%', alignSelf: 'center',
                        }}
                      >
                        <ListItemIcon key={`product-img-${product.id}`}>
                          <img src={`${process.env.REACT_APP_ADAPIFY_SITE_URL}${product.file_path}`} alt="" style={productImage} />
                        </ListItemIcon>
                        <ListItemText key={`product-text-${product.id}`} primary={product.name} sx={productName} />
                      </div>
                      <div style={popperActions}>
                        <div key={`div-${product.id}`} style={quantity}>
                          <TextField
                            key={`product-textfield-${product.id}`}
                            disabled
                            sx={quantityField}
                            variant="standard"
                            type="number"
                            max={max}
                            min={min}
                            label="Quantity"
                            value={product.quantity}
                            onBlur={setMinMax}
                          />
                          <ButtonGroup key={`product-btn-grp-${product.id}`} orientation="vertical">
                            <Button key={`checkout-qu-${product.id}`} sx={quantityUp} onClick={() => btnUpClick(product.quantity, product.id)}>
                              +
                            </Button>
                            <Button key={`checkout-qd-${product.id}`} sx={quantityDown} onClick={() => btnDownClick(product.quantity, product.id)}>
                              -
                            </Button>
                          </ButtonGroup>
                        </div>
                        <ListItemText key={`product-text-2-${product.id}`} primary={`$${parseFloat(product.price * product.quantity).toFixed(2)}`} sx={productPrice} />
                        <div>
                          <Tooltip title="Remove">
                            <Button key={`product-remove-${product.id}`} sx={removeButton} onClick={() => removeProduct(product.id)}>
                              <Icon component={HighlightOffIcon} />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </ListItem>
                    <hr />
                  </div>
                );
              })}

              {priceTotal !== 'No Items in Cart' ? (
                <>
                  <ListItemText
                    disableTypography
                    primary={priceTotal}
                    sx={priceTotalStyle}
                  />
                  <br />
                  <br />
                  <div style={buttonWrapper}>
                    <Button sx={emptyButton} size="medium" color="primary" variant="contained" onClick={openConfirmEmpty}>
                      Empty Cart
                    </Button>
                    <Button sx={checkoutButton} size="medium" color="primary" variant="contained">
                      <a style={checkoutLink} href="/checkout">Checkout</a>
                    </Button>
                  </div>
                  <br />
                  <br />
                </>
              ) : (
                <>
                  <ListItemText
                    disableTypography
                    primary={priceTotal}
                    sx={noItems}
                  />
                </>
              )}
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
      <Dialog sx={dialogBox} open={confirmEmpty}>
        <ClickAwayListener onClickAway={clickAway}>
          <Paper sx={confirmEmptyWrapper}>
            <div style={confirmEmptyTitle}>
              Empty Cart
            </div>
            <div style={confirmEmptyContent}>
              Are you sure you want to empty your cart?
            </div>
            <div style={emptyButtonWrapper}>
              <Button sx={emptyConfirm} size="medium" color="primary" variant="contained" onClick={emptyCart}>
                Confirm
              </Button>
              <Button sx={emptyCancel} size="medium" color="primary" variant="contained" onClick={clickAway}>
                Cancel
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </Dialog>
    </div>
  );
};

export default StoreCart;
